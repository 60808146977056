function validate(form) {
    var required = $('*[required]', form);
    required.each(function () {
        if ($(this).val() == '') {
            $(this).addClass('red').removeClass('green');
        }
        else {
            $(this).addClass('green').removeClass('red');
        }
    });

    if ($('*[required].red', form).length == 0) {

        $('.form').addClass('animation-1');
        $.ajax({
            method: "POST",
            url: "/send.php",
            data: form.serialize(),
            success: function (html) {
                setTimeout(function () {
                    $('.form').addClass('animation-2');
                }, 2000);
            }
        })
    }
}

$(document).ready(function () {
    $('form *[required]').on('change', function () {
        if ($(this).val() == '') {
            $(this).addClass('red').removeClass('green');
        }
        else {
            $(this).addClass('green').removeClass('red');
        }
    })
});